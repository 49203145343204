import React from 'react';
import {
  Link
} from "react-router-dom";
import Data from '../../Data';
import './Welcome.scss';

class Welcome extends React.Component {

  selectFile = (e) => {
    // e.preventDefault();
    if (Data.hasChanged()) {
      if (window.confirm("Loading a file will lose unsaved changes. Do you want to continue?")) {
        document.getElementById("file-input").click();
        this.props.closeFunc();
      }
    } else {
      document.getElementById("file-input").click();
      this.props.closeFunc();
    }
  }

  reset = (e) => {
    e.preventDefault();
    Data.loadDefault();
    this.props.closeFunc();
  }

  render() {
    return (
      <div className="main welcome">
        <div className="modal">
          <div className="">
            <h1>Linkio by Innon</h1>
            <div className="copy">
              You can now configure your LinkIO device or edit an existing LinkIO configuration.

              The tool has three tabs:

              General: to select or view the general settings.

              I/O: to select or view the I/O configuration.

              Functions: to select or view Special Functions selections.

              Load a configuration or start from scratch to create your configuration.
              </div>
          </div>
          <div className="buttons">
            <Link to="/" onClick={this.selectFile}>
              <button className="primary">
                <svg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 24 24" width={20}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z"/>
                </svg>
                Load existing config
              </button>
            </Link>
            <Link to="/" onClick={this.reset}>
              <button className="secondary">
                <svg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 24 24" width={20}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
                </svg>
                Start from scratch
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Welcome;
