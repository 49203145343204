import React from 'react';

class Help extends React.Component {
  render() {
    return (
      <div className="main help">
        <div className="fixed-column">
          <h1>Need assistance?</h1>
          Please contact <a href="mailto:support@innon.co.uk">support@innon.co.uk</a> for any help.
        </div>
      </div>
    )
  }
}

export default Help;
